.report-info {
  border-bottom: 1px solid #1976d2;
  margin-bottom: 40px;
}
.report-title {
  color: #1976d2;
  margin-bottom: .5em;
}
.report-desc {
  color: #666;
  font-size: .875rem;
  margin: .5rem 0 1.5rem;
}

