.price-updates {
  &--table {
    border-bottom: 1px solid #E0E0E0;
    border-left: 2px solid #E0E0E0;
    border-right: 2px solid #E0E0E0;
    border-top: 1px solid #E0E0E0;
    border-collapse: collapse;
    font-size: .875rem;
    width: 100%;
    thead {
      th {
        font-weight: 500;
        min-width: 30px;
        padding: 0 10px;
        text-align: left;
        white-space: nowrap;
      }
    }
    tr {
      border-bottom: 1px solid #E0E0E0;
      height: 46px;
      &.child {
        background-color: rgba(25, 118, 210, .1);
        height: 40px;
        &.last {
          .child-branch {
            &::before {
              height: 20px;
            }
          }
        }
      }
    }
    td {
      padding: 0 10px;
      min-width: 30px;
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }

    }
    .parent-toggle {
      align-items: center;
      border: 1px solid #1976D2;
      border-radius: 4px;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      height: 24px;
      width: 24px;
      svg {
        color: #1976D2;
        font-size: .875rem;
      }
    }
    .child-branch {
      display: inline-block;
      position: relative;
      &::before {
        background-color: rgba(0,0,0,.25);
        content: "";
        display: block;
        left: -1px;
        height: 40px;
        position: absolute;
        top: -24px;
        width: 1.5px;
      }
      &::after {
        background-color: rgba(0,0,0,.25);
        content: "";
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        top: calc(50% - 5px);
        transform: translateY(-50%);
        width: 12px;
      }
    }
  }
}
  