.success-icon {
  color: #1976d2;
  margin-right: 20px;
  svg {
    font-size: 2rem;
  }
}
.signers {
  border-spacing: 0;
  td {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &.email-icon {
      color: #1976d2;
      text-align: center;
      > span {
        background-color: #1976d2;
        border-radius: 1rem;
        display: inline-block;
        height: 2rem;
        line-height: 1;
        padding: 4px;
        width: 2rem;
      }
      img {
        max-width: 1.5rem;
        height: auto;
        line-height: 1;
      }
    }
    input {
      padding: 6.75px 14px;
    }
    .email-text {
      font-size: .875rem;
      font-weight: 700;
    }
    padding: 4px 10px;
  }
}