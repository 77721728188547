.dr {
  &--ctas {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0 30px;
    width: 100%;
    button {
      position: relative;
      &:not(:last-child) {
        margin-right: 2rem;
        &::after {
          background-color: #aaaaaa;
          content: "";
          display: inline-block;
          height: 36px;
          position: absolute;
          right: -1rem;
          width: 1px;
        }
      }
    }
  }
  &--tile-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 30px 30px !important;
  }
  &--tile {
    align-items: center;
    border: 1px solid #1976d2;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    margin: 15px 0;
    padding: 20px 30px;
    width: calc(((100% - 30px)/2));
    &:hover {
      background-color: rgba(25, 118, 210, 0.04);
    }
    h3 {
      margin: 0 0 .5rem;
    }
    p {
      margin: 0;
    }
  }
  &--icon {
    svg {
      color: #1976d2;
      font-size: 4rem;
    }
    margin-right: 1rem;
    
  }
}