.summaries-wrapper {
  &.sticky {
    background-color: white;
    height: auto;
    padding: 10px 24px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
  }
}
.edit-summary {
  &-details {
    flex-grow: 2; &--title {
      font-weight: bold;
      margin: 0 auto .5rem;
    }
    &--info {
      align-items: center;
      display: inline-flex;
      margin: 0 auto;
      span {
        line-height: .75rem;
      }
    }
    &--editor {
      .validated-input--cta {
        color: #1976d2;
        opacity: .7;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &-cta-wrapper {
    align-items: center;
    flex-grow: 1;
    > span { 
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
    button {
      height: 2.5rem;
      min-width: auto;
      padding: 4px 0px;
      width: 100%;
      span {
        margin-right: 0 !important;
        line-height: 1em !important;
      }
    }
  }
  &-icon {
    font-size: 1.125rem !important;
    margin-right: .25rem;
    &.success {
      color: #2e7d32;
    }
    &.info {
      color: #1976d2;
    }
  }
}

.pending-main {
  &.sticky {
    margin-top: 113px;
  }
}

.highland-category {
  &--empty-ux {
    margin-bottom: 8px;
    text-align: center;
  }
  &--empty-icon-wrapper {
    background-color: #1976d2;
    border-radius: 50%;
    display: inline-block;
    height: 25vh;
    margin: 0 auto 20px;
    position: relative;
    width: 25vh;
    svg {
      color: white;
      font-size: calc(25vh * .67);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &--wrapper {
    transition: all ease .5s;
    margin-bottom: 70px;
    &.empty {
      border: 1px solid #1976d2;
      border-radius: 8px;
      background-color: rgba(25, 118, 210, 0.04);
      min-height: calc(100vh - 304px);
      margin-top: 4vh;
      padding: 6vh;
      p {
        margin: .25rem;
        &:last-child() {
          margin-bottom: 0;
        }
      }
    }
  }
  &--button {
    align-items: center;
    border: 1px solid #1976d2;
    border-radius: 6px;
    color: #1976d2;
    display: inline-flex;
    font-size: 1.125rem;
    padding: 6px 24px;
    @media screen and (max-width: 1200px) {
      font-size: .875rem;
      padding: 6px 12px;
    }
    &.deletable {
      &:hover, &:focus {
        background-color: rgba(213, 0, 0, 0.1);
        border-color: #d50000;
        color: #d50000;
        outline: none;
      }
    }
    &.disabled {
      border-color: #aaaaaa;
      color: #aaaaaa;
    }
    &:not(.disabled) {
      cursor: pointer;
    }
    &:hover:not(.disabled, .deletable) {
      background-color: rgba(25, 118, 210, 0.04);
    }
    svg {
      font-size: 1.5rem;
      margin-right: 10px;
    }
  }
  &--accordion {
    &--summary {
      background-color: #1976d2 !important;
      color: white !important;
      height: 42px;
      min-height: auto !important;
      > div {
        margin: 0;
      }
      &.Mui-expanded {
        min-height: inherit !important;
      }
      p {
        font-size: 1.125rem;
        font-weight: bold !important;
      }
      svg {
        color: white;
      }
    }
    &--details {
      padding: 0 !important;
    }
  }
}
.grid-tools {
  button, .dialog-button {
    font-size: 12px;
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
  button, .dialog-button button {
    height: 30px;
    min-width: 30px;
    padding: 4px 2px;
  }
}

.edit-footer {
  background-color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 16px 24px;
}

.textfield-valid {
  label, .MuiFormHelperText-root {
    color: green !important;
  }
  fieldset {
    border: 2px solid green !important;
  }
}
label.textfield-valid, p.textfield-valid {
  color: green !important;
}
.textfield-warning {
  .label, .MuiFormHelperText-root {
    align-items: center;
    color: #e56d29;
    display: inline-flex;
    svg {
      height: 1.25rem;
      margin-right: .375rem;
    }
  }
}