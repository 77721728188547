.role {
  &--accordion {
    &--summary {
      background-color: #1976d2 !important;
      color: white !important;
      height: 42px;
      min-height: auto !important;
      > div {
        margin: 0;
      }
      &.Mui-expanded {
        min-height: inherit !important;
      }
      p {
        font-size: 1.125rem;
        font-weight: bold !important;
      }
      svg {
        color: white;
      }
    }
    &--content {
      display: flex;
      flex-wrap: wrap;
      padding: 30px !important;
    }

    
  }
  &--permission {
    margin: 0 30px 30px 0;
    width: calc((100% - 60px)*.3);
    &:nth-child(3n) {
      margin-right: 0;  
    }
    p {
      color: rgba(0,0,0,.65);
      font-size: .875rem;
      margin: 0;
    }
  }
}
