
.highland {

  &--button {
    align-items: center;
    border: 1px solid #1976d2;
    border-radius: 6px;
    color: #1976d2;
    display: inline-flex;
    font-size: 1.25rem;
    padding: 16px 32px;
    &.disabled {
      color: #aaaaaa;
    }
    &:not(.disabled) {
      cursor: pointer;
    }
    &:hover:not(.disabled) {
      background-color: rgba(25, 118, 210, 0.04);
    }
    svg {
      font-size: 2rem;
      margin-right: 10px;
    }
  }
  &--accordion {
    &--summary {
      background-color: #1976d2 !important;
      color: white !important;
      p {
        font-size: 1.125rem;
        font-weight: bold !important;
      }
      svg {
        color: white;
      }
    }
  }
}