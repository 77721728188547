.MuiDataGrid-row.in-conflict {
  animation: blink .4s step-end 2;
  background-color: #fbead9;
  border-bottom: 1px solid #e56d29;
  border-top: 1px solid #e56d29;
  transition: background-color .3s;
}

.bubble {
  background-color: #aac6e6;
  border-radius: 10px;
  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.4);
  color: #364572;
  font-size: 12px;
  padding: 12px 24px;
  position: fixed;
  width: 260px;
  z-index: 10;
  &::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 120px;
    border-width: 12px 12px 0;
    border-style: solid;
    border-color: #aac6e6  transparent transparent transparent;
    display: block;
    width: 0;
  }
  .bubble-close {
    color: #364572;
    padding: 4px;
    position: absolute;
    right: 4px;
    top: 4px;
  }
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}
