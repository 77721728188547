.header-keyword {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.0075rem;
  input {
    color: white;
  }
  .validated-input--cta {
    color: white;
    opacity: .8;
    &:hover {
      color: inherit;
      opacity: 1;
    }
  }
}
.logo {
  cursor: pointer;
}