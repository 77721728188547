
.validated-input {
  align-items: center;
  display: flex;
  &--icon {
    font-size: 1.125rem !important;
    margin-right: .25rem;
    &--success {
      color: #2e7d32;
    }
    &--info {
      color: #1976d2;
    }
  }
  
  &--more-info {
    border-bottom: 1px dashed #1976d2;
    padding-bottom: 2px;
  }
  &--cta {
    cursor: pointer;
    &:hover {
      transition: all .5s ease;
    }
  }
}